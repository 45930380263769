import React from 'react';
import i18n from 'i18next';
import { getAppDomainName, goTo } from 'common/helpers/domainName.helper';
import { EUserRoles } from 'common/models/userRequestObjects';

export const HeaderButtons = () => {
  const goToLogin = () => {
    const loginUrl = getAppDomainName('/login');
    goTo(loginUrl);
  };

  const goToSignUp = () => {
    const signUpUrl = getAppDomainName(`/sign-up?role=${EUserRoles.Borrower}`);
    goTo(signUpUrl);
  };

  return (
    <div>
      <button className="header-button header-button_sign-in" onClick={goToLogin}>
        {i18n.t<string>('signIn')}
      </button>
      <button className="header-button header-button_sign-up" onClick={goToSignUp}>
        {i18n.t<string>('signUp')}
      </button>
    </div>
  );
};
