import React from 'react';
import i18n from 'i18next';
import { Row, Col } from 'antd';
import CRIF from 'app/assets/images/Partners/CRIF.svg';
import SGE from 'app/assets/images/Partners/SGE.svg';
import Axmit from 'app/assets/images/Partners/Axmit.svg';
import Polyreg from 'app/assets/images/Partners/Polyreg.svg';
import SVC from 'app/assets/images/Partners/SVC.svg';
import Lemonway from 'app/assets/images/Partners/Lemonway.svg';

export const OurPartners = () => {
  const partnerIcons = [CRIF, SGE, Axmit, Polyreg, SVC, Lemonway];

  return (
    <div className="our-partners-section">
      <div className="our-partners_title">{i18n.t<string>('partnership.ourPartnersTitle')}</div>
      <div className="our-partners_text">{i18n.t<string>('partnership.ourPartnersText')}</div>
      <Row type="flex" justify="center" gutter={24}>
        {partnerIcons.map(item => (
          <Col xs={24} md={8} xl={4} key={item} className="our-partners_partner-icon">
            <img src={item} alt={item} width="100%" />
          </Col>
        ))}
      </Row>
    </div>
  );
};
