import React from 'react';
import i18n from 'i18next';

interface IComponentProps {
  title: string;
  value: string;
}

export const CreditRatingItem = (props: IComponentProps) => {
  const { title, value } = props;
  return (
    <div className="credit-rating">
      <div>
        <div className="credit-rating_title">{i18n.t<string>(`mainSection.${title}`)}</div>
        <div className="credit-rating_value">{i18n.t<string>(`mainSection.${value}`)}</div>
      </div>
    </div>
  );
};
