import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18NextXhrBackend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from 'common/locales/en/translation.json';
import enErrors from 'common/locales/en/errors.json';
import deTranslation from 'common/locales/de/translation.json';
import deErrors from 'common/locales/de/errors.json';
import itTranslation from 'common/locales/it/translation.json';
import itErrors from 'common/locales/it/errors.json';
import frTranslation from 'common/locales/fr/translation.json';
import frErrors from 'common/locales/it/errors.json';

const resources = {
  en: {
    translation: enTranslation,
    errors: enErrors
  },
  de: {
    translation: deTranslation,
    errors: deErrors
  },
  it: {
    translation: itTranslation,
    errors: itErrors
  },
  fr: {
    translation: frTranslation,
    errors: frErrors
  }
};

i18n
  .use(I18NextXhrBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage', 'navigator']
    },
    resources,
    ns: ['translation', 'errors'],
    defaultNS: 'translation',
    load: 'languageOnly',
    whitelist: ['de', 'en', 'it', 'fr'],
    fallbackLng: ['en'],
    debug: true
  });

export default i18n;
