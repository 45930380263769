import React from 'react';
import i18n from 'i18next';
import { Col, Row } from 'antd';
import { Trans } from 'react-i18next';
import { EAnchors } from 'common/const';
import { ParalaxContainer } from 'common/components/ParalaxContainer';
import { aboutUsPlx, aboutUsPlanetsPlx } from 'common/PlxConfig';
import AboutUsPlanets from 'app/assets/images/AboutUsPlanets.svg';

export const AboutUs = () => {
  return (
    <div className="about-us__section" id={EAnchors.AboutUs}>
      <Row type="flex" gutter={32} justify="center" align="middle">
        <Col xs={{ span: 24, order: 2 }} xl={{ span: 12, order: 1 }}>
          <div className="about-us__main-title">{i18n.t<string>('aboutUs.mainTitle')}</div>
          <div className="about-us__main-text">{i18n.t<string>('aboutUs.mainText')}</div>
          <div className="about-us__subtitle">{i18n.t<string>('aboutUs.subTitle')}</div>
          <div className="about-us__text">{i18n.t<string>('aboutUs.text')}</div>
        </Col>
        <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }} className="about-us__stat-items">
          <ParalaxContainer
            childrenParallaxData={[{ IParallaxData: aboutUsPlanetsPlx }, { IParallaxData: aboutUsPlx }]}
            className="aboutUs"
          >
            <img src={AboutUsPlanets} alt="Partner Team" width="100%" />
            <div>
              <Row type="flex" justify="center" align="middle" className="stat-item stat-item_big">
                <Col className="text-center">
                  <div className="stat-item__title color-butterfly-bush">
                    <Trans i18nKey="aboutUs.statItemTitle1">
                      295+ <br />
                      <span className="fs-xlg">Billion CHF</span>
                    </Trans>
                  </div>
                  <div className="stat-item__value">{i18n.t<string>('aboutUs.statItemText1')}</div>
                </Col>
              </Row>

              <Row type="flex" justify="center" align="middle" className="stat-item stat-item_small">
                <Col className="text-center">
                  <div className="stat-item__title color-malibu">{i18n.t<string>('aboutUs.statItemTitle2')}</div>
                  <div className="stat-item__value">{i18n.t<string>('aboutUs.statItemText2')}</div>
                </Col>
              </Row>
            </div>
          </ParalaxContainer>
        </Col>
      </Row>
    </div>
  );
};
