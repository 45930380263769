import React from 'react';
import i18n from 'i18next';
import WhiteCheckmark from 'app/assets/icons/WhiteCheckmark.svg';

export const PartnerWithUsPoints = () => {
  const partnerWithUsPoints = ['itemText1', 'itemText2', 'itemText3', 'itemText4'];

  return (
    <div className="partner-with-us_points">
      {partnerWithUsPoints.map(item => (
        <div className="partner-with-us_points_item" key={item}>
          <img src={WhiteCheckmark} alt="White Checkmark" className="mr-5" />
          <span>{i18n.t<string>(`partnership.${item}`)}</span>
        </div>
      ))}
    </div>
  );
};
