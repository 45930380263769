import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { mdfEmail, mdfTel } from 'common/const';

export const ContactUs = () => {
  return (
    <div className="contact-us">
      <div className="contact-us__title">{i18n.t<string>('contactUs.title')}</div>
      <div className="contact-us__text mb-8">
        <Trans i18nKey="contactUs.text">
          <a href={`mailto:${mdfEmail}`}>Contact us</a> and we’ll give you an answer within minutes.
        </Trans>
      </div>
      <div className="contact-us__text mb-3">{i18n.t<string>('contactUs.speakToManager')}</div>
      <div className="contact-us__call-us">
        {i18n.t<string>('contactUs.callUsTo')} <a href={`tel:${mdfTel}`}>{mdfTel}</a>
      </div>
    </div>
  );
};
