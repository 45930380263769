export const creditRatingsItems = [
  {
    itemKey: '1creditRatingKey',
    itemValue: '1creditRatingValue'
  },
  {
    itemKey: '4creditRatingKey',
    itemValue: '4creditRatingValue'
  },
  {
    itemKey: '2creditRatingKey',
    itemValue: '2creditRatingValue'
  },
  {
    itemKey: '3creditRatingKey',
    itemValue: '3creditRatingValue'
  },
  {
    itemKey: '5creditRatingKey',
    itemValue: '5creditRatingValue'
  }
];
