import ReactGA from 'react-ga';
import window from 'global/window';

export const initGA = (trackingCode: string) => {
  ReactGA.initialize(trackingCode);
};

export const pageView = (page: string = window.location.pathname + window.location.search) => {
  ReactGA.pageview(page);
};

export const emitEvent = (category: string, action: string) => {
  ReactGA.event({ category, action });
};
