import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import MainLogo from 'app/assets/images/MainLogo.svg';
import { HeaderMenu } from 'entities/components/Header/HeaderMenu';
import { HeaderButtons } from 'entities/components/Header/HeaderButtons';

interface IComponentProps {
  hideMenuItems?: boolean;
}

type TAllProps = IComponentProps & RouteComponentProps;

const HeaderComponent = (props: TAllProps) => {
  const goToMain = () => {
    const { history } = props;
    history.push('/');
  };

  const { hideMenuItems } = props;

  return (
    <div className="header">
      <div className="mr-4 pointer" onClick={goToMain}>
        <img src={MainLogo} alt="Main Logo" />
      </div>
      {!hideMenuItems && <HeaderMenu />}
      {!hideMenuItems && <HeaderButtons />}
    </div>
  );
};

export const Header = withRouter(HeaderComponent);
