import React from 'react';
import i18n from 'i18next';

interface IComponentProps {
  paragraph: { title?: string; text?: string[] };
}

class AcademyTilePageParagraphComponent extends React.Component<IComponentProps> {
  render() {
    const { paragraph } = this.props;
    const { title, text } = paragraph;

    return (
      <>
        {title && <div className="mt-12 pt-7 mb-7 sub-title tile-title">{i18n.t<string>(title)}</div>}
        {text &&
          text.map((item, index) => {
            return (
              <div className="mb-6 tile-text" key={index}>
                {i18n.t<string>(item)}
              </div>
            );
          })}
      </>
    );
  }
}

export const AcademyTilePageParagraph = AcademyTilePageParagraphComponent;
