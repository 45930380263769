import { Reducer } from 'redux';
import { RouterState } from 'react-router-redux';

type RoutingReducer = Reducer<RouterState>;

export interface IApplicationState {
  routing?: RoutingReducer | null;
  [key: string]: any;
}

const reducers = {};

export default reducers;
