import React from 'react';
import i18n from 'i18next';
import { Col, Row } from 'antd';
import { ImageSVG } from 'common/components/ImageSVG';
import { goTo } from 'common/helpers/domainName.helper';
import { academyTiles } from 'entities/components/Academy/Academy.const';

class AcademyComponent extends React.Component {
  render() {
    return (
      <Row type="flex" justify="center" className="common-layout">
        <Col span={24}>
          <Row type="flex" justify="center">
            <Col>
              <div className="mb-7 academy-title text-center">{i18n.t<string>('academyTitleText')}</div>
              <div className="text-center fs-sm color-amethyst-smoke">{i18n.t<string>('academyTitleTooltip')}</div>
            </Col>
          </Row>
          <Col className="tiles-row">
            {academyTiles.map((item, index) => (
              <Row type="flex" className="academy-tile" onClick={() => goTo(item.path)} key={index}>
                <div className="mb-6 mr-11 tile-image">
                  <ImageSVG name={item.imageName} />
                </div>
                <Col>
                  <div className="sub-title tile-title mb-5">{i18n.t<string>(item.title)}</div>
                  <div className="tile-text">{i18n.t<string>(item.text)}</div>
                </Col>
              </Row>
            ))}
          </Col>
        </Col>
      </Row>
    );
  }
}

export const Academy = AcademyComponent;
