import React from 'react';
import i18n from 'i18next';
import { Col } from 'antd';

interface IComponentProps {
  step: string;
  title: string;
  text: string;
}

export const HowItWorksItem = (props: IComponentProps) => {
  const { step, text, title } = props;
  return (
    <Col xs={24} md={12} lg={6} className="how-it-works-item">
      <div className="how-it-works-item_step">{step}</div>
      <div className="how-it-works-item_title">{i18n.t<string>(`howItWorks.${title}`)}</div>
      <div className="how-it-works-item_text">{i18n.t<string>(`howItWorks.${text}`)}</div>
    </Col>
  );
};
