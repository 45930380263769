import React from 'react';
import Axios, { AxiosResponse } from 'axios';
import i18n from 'i18next';
import { ISimpleUser } from 'common/models/userRequestObjects';
import { Button, Input, Row, Col, message, Form } from 'antd';
import { ParalaxContainer } from 'common/components/ParalaxContainer';
import ManWithJetpack from 'app/assets/images/ManWithJetpack.svg';
import ManWithJetpackPlanets from 'app/assets/images/ManWithJetpackPlanets.svg';
import { mainImagePlx, mainImagePlxBackground } from 'common/PlxConfig';
import { FormComponentProps } from 'antd/lib/form';

export class MainFieldComponent extends React.Component<FormComponentProps> {
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="main-section-layout ">
        <Row type="flex" gutter={32}>
          <Col xs={{ span: 24, order: 2 }} xl={{ span: 12, order: 1 }} className="image-sm-fix">
            <h1 className="main-title ff-bold">{i18n.t<string>('comingSoonPage.title')}</h1>

            <p className="main-text">{i18n.t<string>('comingSoonPage.text')}</p>

            <Form onSubmit={this.sendEmail}>
              <div className="sub-title mb-4 color-gun-powder">{i18n.t<string>('comingSoonPage.placeholder')}</div>
              {getFieldDecorator('email')(
                <Input
                  type="email"
                  className="cta-input"
                  suffix={<Button htmlType="submit">{i18n.t<string>('comingSoonPage.button')}</Button>}
                />
              )}
            </Form>
          </Col>

          <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }} className="text-center image-sm-fix">
            <ParalaxContainer
              childrenParallaxData={[{ IParallaxData: mainImagePlxBackground }, { IParallaxData: mainImagePlx }]}
              className="mainImage"
            >
              <img src={ManWithJetpackPlanets} alt="Man with jetpack" width="100%" className="main-image" />
              <img src={ManWithJetpack} alt="Man with jetpack" width="100%" className="main-image" />
            </ParalaxContainer>
          </Col>
        </Row>
      </div>
    );
  }

  sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      const { email } = values;

      if (!email) {
        message.error(i18n.t<string>('enterCorrectEmail'), 5);
        return;
      }

      const userEmail: ISimpleUser = { email };
      const url = 'requests/simple';

      Axios.post(url, userEmail)
        .then(function() {
          message.success(i18n.t<string>('thankForRequest'), 5);
        })
        .catch(function(error: AxiosResponse) {
          switch (error.status) {
            case 409:
              message.error(i18n.t<string>('requestExist'));
              break;
            case 422:
              message.error(i18n.t<string>('enterCorrectEmail'));
              break;

            default:
              message.error(i18n.t<string>('unexpectedError'));
              break;
          }
        });
    });
  };
}

export const MainField = Form.create()(MainFieldComponent);
