import React from 'react';
import { pageView } from 'common/analytics';
import { MainSection } from 'entities/components/MainSection/MainSection';
import { Profits } from 'entities/components/Profits/Profits';
import { LoanTypes } from 'entities/components/LoanTypes/LoanTypes';
import { HowItWorks } from 'entities/components/HowItWorks/HowItWorks';
import { CTASection } from 'entities/components/CTASection/CTASection';
import { P2PLending } from 'entities/components/P2PLending/P2PLending';
import { Requirements } from 'entities/components/Requirements/Requirements';
import { WhyMidfunder } from 'entities/components/WhyMidfunder/WhyMidfunder';
import { Partnership } from 'entities/components/Partnership/Partnership';
import { AboutUs } from 'entities/components/AboutUs/AboutUs';
import { OurTeam } from 'entities/components/OurTeam/OurTeam';
import { CareerAtMidfunder } from 'entities/components/CareerAtMidfunder/CareerAtMidfunder';
import { OurValues } from 'entities/components/OurValues/OurValues';
import { ContactUs } from 'entities/components/ContactUs/ContactUs';

class MainLayoutComponent extends React.Component {
  componentDidMount() {
    pageView();
  }

  render() {
    return (
      <div className="main-layout">
        <MainSection />
        <Profits />
        <LoanTypes />
        <HowItWorks />
        <CTASection />
        <P2PLending />
        <Requirements />
        <WhyMidfunder />
        <Partnership />
        <AboutUs />
        <OurTeam />
        <CareerAtMidfunder />
        <OurValues />
        <ContactUs />
      </div>
    );
  }
}

export const MainLayout = MainLayoutComponent;
