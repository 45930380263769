import React from 'react';
import i18n from 'i18next';
import { Button, Input } from 'antd';
import { getAppDomainName, goTo } from 'common/helpers/domainName.helper';

interface IComponentProps {
  pathTo?: string;
}

export const CTAInput = (props: IComponentProps) => {
  const goToPath = () => {
    const { pathTo = '/' } = props;
    const signUpUrl = getAppDomainName(pathTo);
    goTo(signUpUrl);
  };

  return (
    <div>
      <div className="sub-title mb-4 color-gun-powder">{i18n.t<string>('CTA.placeholder')}</div>
      <Input
        className="cta-input"
        placeholder="CHF"
        suffix={<Button onClick={goToPath}>{i18n.t<string>('CTA.button')}</Button>}
      />
    </div>
  );
};
