import React from 'react';
import { MainField } from 'entities/components/ComingSoonPage/MainField';
import { Footer } from 'entities/components/Footer/Footer';
import { Header } from 'entities/components/Header/Header';

export class ComingSoonPage extends React.Component {
  render() {
    return (
      <div className="coming-soon">
        <Header hideMenuItems />
        <MainField />
        <Footer mode="minimal" />
      </div>
    );
  }
}
