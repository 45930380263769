import React from 'react';
import i18n from 'i18next';
import { Col, Row } from 'antd';
import { EAnchors } from 'common/const';
import Frei from 'app/assets/images/Team/Frei.png';
import Schumperli from 'app/assets/images/Team/Schumperli.png';
import Popescu from 'app/assets/images/Team/Popescu.png';
import Umbricht from 'app/assets/images/Team/Umbricht.png';
import Konigsberg from 'app/assets/images/Team/Konigsberg.png';
import Pages from 'app/assets/images/Team/Pages.png';
import Hagnauer from 'app/assets/images/Team/Hagnauer.png';
import Perschak from 'app/assets/images/Team/Perschak.png';
import Kuhn from 'app/assets/images/Team/Kuhn.png';
import Lortscher from 'app/assets/images/Team/Lortscher.png';
import Cherepanov from 'app/assets/images/Team/Cherepanov.png';
import Tershukov from 'app/assets/images/Team/Tershukov.png';
import Lezhnev from 'app/assets/images/Team/Lezhnev.png';
import Turcevich from 'app/assets/images/Team/Turcevich.png';
import Bentley from 'app/assets/images/Team/Bentley.png';
import Teddy from 'app/assets/images/Team/Teddy.png';
import { MemberTileHorizontal } from 'entities/components/OurTeam/MemberTileHorizontal';
import { MemberTileVertical } from 'entities/components/OurTeam/MemberTileVertical';

export const OurTeam = () => {
  return (
    <div className="team" id={EAnchors.Team}>
      <div className="team__tooltip">{i18n.t<string>('ourTeam.tooltip')}</div>

      <div className="team__block">
        <div className="team__main-title">{i18n.t<string>('ourTeam.managementTitle')}</div>
        <Row type="flex" gutter={32} className="mb-11">
          {management.map(item => (
            <MemberTileHorizontal {...item} key={item.memberName} />
          ))}
        </Row>
      </div>

      <div className="team__block">
        <div className="team__main-title">{i18n.t<string>('ourTeam.devTeamTitle')}</div>
        <Row type="flex" gutter={32}>
          {devTeam.map(item => (
            <MemberTileVertical {...item} key={item.memberName} />
          ))}
        </Row>
      </div>

      <div className="team__block">
        <div className="team__main-title">{i18n.t<string>('ourTeam.mascotsTitle')}</div>
        <div className="mascots">
          <Row type="flex" gutter={12} justify="center">
            <Col span={12}>
              <img src={Bentley} alt={Bentley} className="mascots__image" />
            </Col>
            <Col span={12}>
              <img src={Teddy} alt={Teddy} className="mascots__image" />
            </Col>
          </Row>
          <div className="mascots__name">Bentley & Teddy</div>
        </div>
      </div>

      <div className="team__block">
        <div className="team__main-title">{i18n.t<string>('ourTeam.advisorsTitle')}</div>
        <Row type="flex" gutter={32}>
          {advisors.map(item => (
            <MemberTileHorizontal {...item} key={item.memberName} />
          ))}
        </Row>
      </div>
    </div>
  );
};

const management = [
  {
    icon: Frei,
    memberName: 'Patrick O.Frei',
    memberPos: '1ManagementMemberPos',
    memberAchieve: '1ManagementMemberAch'
  },
  {
    icon: Schumperli,
    memberName: 'Pascal Schümperli',
    memberPos: '2ManagementMemberPos',
    memberAchieve: '2ManagementMemberAch'
  },
  {
    icon: Popescu,
    memberName: 'Theo Popescu',
    memberPos: '3ManagementMemberPos',
    memberAchieve: '3ManagementMemberAch'
  },
  {
    icon: Umbricht,
    memberName: 'Roger Umbricht',
    memberPos: '4ManagementMemberPos',
    memberAchieve: '4ManagementMemberAch'
  }
];

const advisors = [
  {
    icon: Konigsberg,
    memberName: 'Dr. Amir Konigsberg',
    memberPos: '1AdvisorMemberPos',
    memberAchieve: '1AdvisorMemberAch'
  },
  {
    icon: Pages,
    memberName: 'Andreas Pages',
    memberPos: '2AdvisorMemberPos',
    memberAchieve: '2AdvisorMemberAch'
  },
  {
    icon: Hagnauer,
    memberName: 'Marcel Hagnauer',
    memberPos: '3AdvisorMemberPos',
    memberAchieve: '3AdvisorMemberAch'
  },
  {
    icon: Perschak,
    memberName: 'Christian Perschak',
    memberPos: '4AdvisorMemberPos',
    memberAchieve: '4AdvisorMemberAch'
  },
  {
    icon: Kuhn,
    memberName: 'Dr. Hans Kuhn LL.M.',
    memberPos: '5AdvisorMemberPos',
    memberAchieve: '5AdvisorMemberAch'
  },
  {
    icon: Lortscher,
    memberName: 'Pierre-Yves Lörtscher',
    memberPos: '6AdvisorMemberPos',
    memberAchieve: '6AdvisorMemberAch'
  }
];

const devTeam = [
  {
    icon: Cherepanov,
    memberName: 'Andrei Cherepanov',
    memberPos: '1DevTeamMemberPos',
    memberAchieve: ''
  },
  {
    icon: Tershukov,
    memberName: 'Ivan Tershukov',
    memberPos: '2DevTeamMemberPos',
    memberAchieve: ''
  },
  {
    icon: Lezhnev,
    memberName: 'Ilya Lezhnev',
    memberPos: '3DevTeamMemberPos',
    memberAchieve: ''
  },
  {
    icon: Turcevich,
    memberName: 'Alex Turcevich',
    memberPos: '4DevTeamMemberPos',
    memberAchieve: ''
  }
];
