import React from 'react';
import { Col, Row } from 'antd';
import i18n from 'i18next';

export const PrivacyInfo = () => {
  return (
    <Row type="flex" gutter={[24, 24]} className="privacy-info-section">
      <Col lg={6} sm={12} xs={24}>
        <span>{i18n.t<string>('footer.privacyInfo1')}</span>
      </Col>
      <Col lg={6} sm={12} xs={24}>
        <span>{i18n.t<string>('footer.privacyInfo2')}</span>
      </Col>
      <Col lg={6} sm={12} xs={24}>
        <span>{i18n.t<string>('footer.privacyInfo3')}</span>
      </Col>
      <Col lg={6} sm={12} xs={24}>
        <span>{i18n.t<string>('footer.privacyInfo4')}</span>
      </Col>
    </Row>
  );
};
