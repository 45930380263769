import React from 'react';
import { mdfEmail, mdfTel } from 'common/const';
import { CommonPrivacyLayout, MainTitle } from 'app/assets/styles/base/commonStyledComponents';
import { LegalNoticeText, LegalNoticeLink } from 'entities/components/PrivacyPolicy/LegalNoticeLayout';

export const LegalNotice: React.FC = () => {
  return (
    <CommonPrivacyLayout>
      <MainTitle>LEGAL NOTICE</MainTitle>
      <LegalNoticeText>Midfunder AG</LegalNoticeText>
      <LegalNoticeText>Dufourstrasse 43</LegalNoticeText>
      <LegalNoticeText>8008 Zürich</LegalNoticeText>
      <LegalNoticeText>Phone: {mdfTel}</LegalNoticeText>
      <LegalNoticeLink href={`mailto:${mdfEmail}`}>{mdfEmail}</LegalNoticeLink>
      <LegalNoticeText>Commercial register: CHE-142.018.503</LegalNoticeText>
      <LegalNoticeText>Company headquarters: 8008 Zürich</LegalNoticeText>
      <LegalNoticeText>
        Responsibility of our website: <LegalNoticeLink href={`mailto:${mdfEmail}`}>{mdfEmail}</LegalNoticeLink>
      </LegalNoticeText>
    </CommonPrivacyLayout>
  );
};
