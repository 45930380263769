import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import { initGA } from 'common/analytics';
import createStore from 'app/store/createStore';
import App from 'app/pages/App';
import 'app/assets/styles/Styles.scss';

// i18n initialization
import 'i18n';

// Axios initialization
axios.defaults.baseURL = `/api`;
axios.interceptors.request.use(config => config);
axios.interceptors.response.use(
  response => response,
  error => {
    const { status, statusText, data } = error.response;

    return Promise.reject({ data, status, statusText });
  }
);

// React-GA initialization
initGA('G-DZZQ0TFG1C');

// Render Setup

const MOUNT_NODE = document.getElementById('root');
const initialState = (window as any).___INITIAL_STATE__;
const history = createBrowserHistory();
const store = createStore(initialState, history);

ReactDOM.render(
  <Provider store={store}>
    {/* @ts-ignore*/}
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  MOUNT_NODE
);
