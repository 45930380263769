import React, { Component } from 'react';
import i18n from 'i18next';
import { Col, Row, Collapse, Icon } from 'antd';
import window from 'global/window';
import { Trans } from 'react-i18next';
import { ImageSVG } from 'common/components/ImageSVG';
import { EUserRoles } from 'common/models/userRequestObjects';
import { investorsFaq, borrowersFaq } from 'entities/components/FAQ/FAQContent';

class FaqComponent extends Component {
  render() {
    const role = window.location && window.location.href.split('faq-')[1];
    const isInvestor = role === EUserRoles.Investor;
    const isBorrower = role === EUserRoles.Borrower;

    const title = (isInvestor && 'faqTitleForInvestors') || (isBorrower && 'faqTitleForBorrowers') || '';
    const faqData = (isInvestor && investorsFaq) || (isBorrower && borrowersFaq) || [];

    return (
      <Row type="flex" justify="center" className="faq-layout">
        <Col md={24} lg={20} xl={18} xxl={16}>
          <div className="mb-11 pb- secondary-title text-center faq-title">
            <Trans i18nKey={title}>
              Frequently Asked Questions <span className="ff-bold">from</span>
            </Trans>
          </div>

          <div className="faq-image">
            <ImageSVG name="FAQ" />
          </div>

          <div className="custom-collapse">
            {/* @ts-ignore */}
            <Collapse
              bordered={false}
              // @ts-ignore
              expandIcon={({ isActive }) => <Icon type={isActive ? 'minus' : 'plus'} />}
              expandIconPosition="left"
            >
              {faqData.map((item, index) => (
                // @ts-ignore
                <Collapse.Panel header={i18n.t<string>(item.title)} key={index}>
                  <p>{i18n.t<string>(item.text)}</p>
                  {item.secondParagraph && <p>{i18n.t<string>(item.secondParagraph)}</p>}
                </Collapse.Panel>
              ))}
            </Collapse>
          </div>
        </Col>
      </Row>
    );
  }
}

export const FAQ = FaqComponent;
