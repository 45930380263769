import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import i18n from 'i18next';
import { ERoutes } from 'common/const';

interface IComponentProps {
  hidePrivacyLinks?: boolean;
}

export const PrivacyLinks = (props: IComponentProps) => {
  const { hidePrivacyLinks } = props;
  return (
    <Row type="flex" gutter={[16, 16]} className="privacy-links-section">
      <Col lg={12} xs={24}>
        {!hidePrivacyLinks && (
          <Row type="flex" gutter={[16, 16]}>
            <Col md={6} xs={12}>
              <Link to={ERoutes.LegalNotice}>{i18n.t<string>('footer.privacy1')}</Link>
            </Col>
            <Col md={6} xs={12}>
              <Link to={ERoutes.UserTerms}>{i18n.t<string>('footer.privacy2')}</Link>
            </Col>
            <Col md={6} xs={12}>
              <Link to="/">{i18n.t<string>('footer.privacy3')}</Link>
            </Col>
            <Col md={6} xs={12}>
              <Link to={ERoutes.DataPrivacy}>{i18n.t<string>('footer.privacy4')}</Link>
            </Col>
          </Row>
        )}
      </Col>
      <Col lg={12} xs={24} className="text-right">
        {i18n.t<string>('footer.copyright', { date: new Date().getFullYear() })}
      </Col>
    </Row>
  );
};
