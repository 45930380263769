import React from 'react';
import i18n from 'i18next';
import { Col } from 'antd';

interface IComponentProps {
  icon: string;
  memberName: string;
  memberPos: string;
}

export const MemberTileVertical = (props: IComponentProps) => {
  const { icon, memberName, memberPos } = props;
  return (
    <Col xs={24} md={12} lg={6} className="member-tile text-center">
      <img src={icon} alt={icon} width="100%" className="member-tile__image" />
      <div className="member-tile__name">{memberName}</div>
      <div className="member-tile__position">{i18n.t<string>(`ourTeam.${memberPos}`)}</div>
    </Col>
  );
};
