import React from 'react';
import i18n from 'i18next';

interface IComponentProps {
  title: string;
  text: string;
}

export const InfoItem = (props: IComponentProps) => {
  const { title, text } = props;
  return (
    <div className="info-item">
      <div className="info-item__title">{i18n.t<string>(title)}</div>
      <div className="info-item__text">{i18n.t<string>(text)}</div>
    </div>
  );
};
