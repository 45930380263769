import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import window from 'global/window';

interface IComponentProps {
  children: React.ReactNode;
}

type AllTypes = IComponentProps & RouteComponentProps;

class ScrollHandlerComponent extends React.Component<AllTypes> {
  componentDidMount() {
    this.handleScroll();
  }

  componentDidUpdate(prevProps: RouteComponentProps) {
    const { location } = this.props;
    const { pathname, hash } = location;
    if (pathname !== prevProps.location.pathname || hash !== prevProps.location.hash) {
      this.handleScroll();
    }
  }

  render() {
    const { children } = this.props;

    return children;
  }

  handleScroll = () => {
    const { location } = this.props;
    const { hash } = location;
    const element = document.getElementById(hash);

    // Temporary mock value for offset while header in development
    window.scrollTo({ top: element ? element.offsetTop - 170 : 0, behavior: element ? 'smooth' : 'auto' });
  };
}

export const ScrollHandler = withRouter(ScrollHandlerComponent);
