import React from 'react';
import { Card, Col, Form, Input, Row, Slider, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import i18n from 'i18next';
import numeral from 'numeral';
import window from 'global/window';
import { Trans } from 'react-i18next';
import { ImageSVG } from 'common/components/ImageSVG';
import { queryToObject } from 'common/helpers/queryFormatter.helper';
import {
  currency,
  ECalcFieldNames,
  ECreditInfo,
  intFormat,
  IScheduleItem,
  marks,
  percentsByRating
} from 'entities/components/LoanCalculator/LoanCalculator.const';

interface IComponentProps {}

type AllProps = IComponentProps & FormComponentProps;

class LoanCalculatorComponent extends React.Component<AllProps> {
  constructor(props: AllProps) {
    super(props);
    const values = this.getValuesFromQuery();

    if (Object.keys(values).length > 0) {
      const { sum, period, rating } = values;
      calculate(sum, period, rating);
    }
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const hasData = paymentData.length > 0;

    return (
      // TODO refactor HeaderLayout to avoid explicit definition spaces by magic numbers
      <Row type="flex" justify="center" className="calculator-layout">
        <Col span={24} className="mb-9 text-center secondary-title">
          <Trans i18nKey="calculator.title">
            Calculate your <br />
            <span className="ff-bold">business loan repayments</span>
          </Trans>
        </Col>
        <Col xs={24} sm={24} md={21} lg={21} xl={17} xxl={14} className="calculator-wrapper">
          <Row type="flex" justify="center" gutter={30}>
            <Col xs={18} sm={18} md={14} lg={10} xl={10} xxl={8}>
              <Form>
                <div className="mb-4 fs-lg color-grayDark">{i18n.t<string>('calculator.amountTitle')}</div>
                <Form.Item className="input-sum input-custom mb-9">
                  {getFieldDecorator('sum', {
                    initialValue: this.getValuesFromQuery(ECalcFieldNames.Sum),
                    rules: [{ validator: this.validator }]
                  })(<Input size="large" placeholder="500,000" suffix={currency} />)}
                </Form.Item>
                <div className="mb-4 fs-lg color-grayDark">{i18n.t<string>('calculator.periodTitle')}</div>
                <Row type="flex" justify="start" align="middle" className="mb-9">
                  <Form.Item className="input-period input-custom">
                    {getFieldDecorator('period', {
                      initialValue: this.getValuesFromQuery(ECalcFieldNames.Period),
                      rules: [{ validator: this.validator }]
                    })(<Input size="large" placeholder="12" suffix={<div>{i18n.t<string>('calculator.month')}</div>} />)}
                  </Form.Item>
                </Row>
                <div className="mb-9">
                  <div className="mb-4 fs-lg color-grayDark">{i18n.t<string>('calculator.selectRating')}</div>
                  <Card className="borderless-card calculator-card mb-4">
                    <Form.Item>
                      {getFieldDecorator('rating', {
                        initialValue: this.getValuesFromQuery(ECalcFieldNames.Rating)
                      })(<Slider marks={marks} step={20} className="m-0 mt-2 mr-5 mb-2 ml-1 score-slider" tipFormatter={null} />)}
                    </Form.Item>
                  </Card>
                  <div className="color-grayDark">{i18n.t<string>(this.getRatingDescription())}</div>
                </div>
              </Form>
            </Col>
            <Col xs={0} sm={0} md={0} lg={14} xl={14} xxl={14} className="calculator-image">
              <ImageSVG name="Presentation" />
            </Col>
          </Row>

          {isRatingHighest ? (
            <Row type="flex" justify="center" className="mb-9 fs-lg ff-medium color-black">
              {i18n.t<string>('calculator.ratingWarning')}
            </Row>
          ) : (
            <Row type="flex" justify="space-around" className="mb-6">
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Row type="flex" justify="space-around" className="mb-9 flex-nowrap">
                  <div className="bordered-card card-info mr-4 ml-4 p-4">
                    <Row type="flex" justify="center">
                      <span className="fs-xlg ff-medium tight-letters color-black card-tooltip">{this.getPercent()}</span>
                      <span className="fs-xlg ff-medium color-malibu card-tooltip pl-4">%</span>
                    </Row>
                    <Row type="flex" justify="center" className="fs-xs text-center color-grayDark card-tooltip">
                      {i18n.t<string>('paymentSchedule.interestRate')}
                    </Row>
                  </div>
                  <div className="bordered-card card-info mr-4 ml-4 p-4">
                    <Row type="flex" justify="center">
                      <span className="fs-xlg ff-medium tight-letters color-black card-tooltip">
                        {this.getValue(ECreditInfo.Total)}
                      </span>
                      <span className="fs-xlg ff-medium color-malibu card-tooltip pl-4">{currency}</span>
                    </Row>
                    <Row type="flex" justify="center" className="fs-xs text-center color-grayDark card-tooltip">
                      {i18n.t<string>('paymentSchedule.totalRepayment')}
                    </Row>
                  </div>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Row type="flex" justify="space-around" className="mb-9 flex-nowrap">
                  <div className="bordered-card card-info mr-4 ml-4 p-4">
                    <Row type="flex" justify="center">
                      <span className="fs-xlg ff-medium tight-letters color-black card-tooltip">
                        {this.getValue(ECreditInfo.Interest)}
                      </span>
                      <span className="fs-xlg ff-medium color-malibu card-tooltip pl-4">{currency}</span>
                    </Row>
                    <Row type="flex" justify="center" className="fs-xs text-center color-grayDark card-tooltip">
                      {i18n.t<string>('paymentSchedule.totalInterest')}
                    </Row>
                  </div>
                  <div className="bordered-card card-info mr-4 ml-4 p-4">
                    <Row type="flex" justify="center">
                      <span className="fs-xlg ff-medium tight-letters color-black card-tooltip">
                        {this.getValue(ECreditInfo.MonthlyPayment)}
                      </span>
                      <span className="fs-xlg ff-medium color-malibu card-tooltip pl-4">{currency}</span>
                    </Row>
                    <Row type="flex" justify="center" className="fs-xs text-center color-grayDark card-tooltip">
                      {i18n.t<string>('paymentSchedule.monthlyPayment')}
                    </Row>
                  </div>
                </Row>
              </Col>
            </Row>
          )}

          {hasData && (
            <div className="mb-11">
              <Table dataSource={paymentData} pagination={false} className="calculator-table">
                <Table.Column
                  title={i18n.t<string>('paymentSchedule.column1')}
                  dataIndex="period"
                  align="center"
                  className="pt-3 pb-3 fs-sm color-black"
                />
                <Table.Column
                  title={i18n.t<string>('paymentSchedule.column2')}
                  dataIndex="repayment"
                  align="center"
                  className="pt-3 pb-3 fs-sm color-black"
                />
                <Table.Column
                  title={i18n.t<string>('paymentSchedule.column3')}
                  dataIndex="payment"
                  align="center"
                  className="pt-3 pb-3 fs-sm color-black"
                />
                <Table.Column
                  title={i18n.t<string>('paymentSchedule.column4')}
                  dataIndex="total"
                  align="center"
                  className="pt-3 pb-3 fs-sm color-black"
                />
              </Table>
            </div>
          )}

          <Row type="flex" justify="center" className="calculator-info-layout">
            <Col>
              <div className="mb-5 secondary-title text-center font-family-bold">{i18n.t<string>('descriptionTitle1')}</div>
              <div className="mb-11 pb-4 middle-text text-center">{i18n.t<string>('descriptionText1')}</div>
              <div className="mb-5 sub-title">{i18n.t<string>('descriptionTitle2')}</div>
              <div className="mb-11 middle-text">{i18n.t<string>('descriptionText2')}</div>
              <div className="mb-5 sub-title">{i18n.t<string>('descriptionTitle4')}</div>
              <div className="mb-5 middle-text">{i18n.t<string>('descriptionText4Subtext1')}</div>
              <div className="mb-5 middle-text">{i18n.t<string>('descriptionText4Subtext2')}</div>
              <div className="mb-5 middle-text">{i18n.t<string>('descriptionText4Subtext3')}</div>
              <div className="mb-5 middle-text">{i18n.t<string>('descriptionText4Subtext4')}</div>
              <div className="middle-text">{i18n.t<string>('descriptionText4Subtext5')}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  validator = (rule: any, value: string) => {
    const { form } = this.props;
    const { setFields } = form;
    const fieldName = rule.field;
    const minSum = 10000;
    const maxSum = 2500000;
    const minPeriod = 6;
    const maxPeriod = 60;

    const numericValue = parseInt(value);

    switch (fieldName) {
      case 'sum':
        if (isNaN(numericValue)) {
          setFields({ sum: { value: null, errors: [{ message: `${i18n.t('calculator.errOnlyNumbers')}` }] } });
          return;
        }
        if (numericValue < minSum) {
          setFields({ sum: { value: numericValue, errors: [{ message: `${i18n.t('calculator.errGreaterThan')} ${minSum}!` }] } });
          return;
        }
        if (numericValue > maxSum) {
          setFields({ sum: { value: numericValue, errors: [{ message: `${i18n.t('calculator.errLessThan')} ${maxSum}!` }] } });
          return;
        }
        setFields({ sum: { value: numericValue } });
        return;

      case 'period':
        if (isNaN(numericValue)) {
          setFields({ period: { value: null, errors: [{ message: `${i18n.t('calculator.errOnlyNumbers')}` }] } });
          return;
        }
        if (numericValue < minPeriod) {
          setFields({
            period: { value: numericValue, errors: [{ message: `${i18n.t('calculator.errGreaterThan')} ${minPeriod}!` }] }
          });
          return;
        }
        if (numericValue > maxPeriod) {
          setFields({
            period: { value: numericValue, errors: [{ message: `${i18n.t('calculator.errLessThan')} ${maxPeriod}!` }] }
          });
          return;
        }
        setFields({ period: { value: numericValue } });
        return;

      default:
        return;
    }
  };

  getPercent = () => {
    const { form } = this.props;
    const { getFieldValue } = form;

    const percent = getFieldValue('rating');
    const rating = marks[percent];
    const percentValue = percentsByRating[rating];

    if (percentValue === 0) {
      return '-';
    }
    return percentValue;
  };

  getValue = (type: ECreditInfo) => {
    if (!paymentData.length) {
      return '-';
    }

    switch (type) {
      case ECreditInfo.Total:
        return paymentData[paymentData.length - 1].total.split('$');
      case ECreditInfo.Interest:
        return paymentData[paymentData.length - 1].payment.split('$');
      case ECreditInfo.MonthlyPayment:
        return paymentData[0].total.split('$');
      default:
        return null;
    }
  };

  getValuesFromQuery = (valueName?: ECalcFieldNames) => {
    const queries = window.location && window.location.search;
    const data = queryToObject(queries);

    switch (valueName) {
      case ECalcFieldNames.Sum:
      case ECalcFieldNames.Period:
        return data[valueName] ? Number(data[valueName]) : undefined;
      case ECalcFieldNames.Rating:
        return data[valueName] ? Number(data[valueName]) : 0;
      default:
        return data;
    }
  };

  getRatingDescription = () => {
    const { form } = this.props;
    const { getFieldValue } = form;

    const ratingValue: number = getFieldValue('rating');
    return `calculator.rating.${marks[ratingValue]}`;
  };
}

let isRatingHighest: boolean = false;

let paymentData: IScheduleItem[] = [];

const calculate = (sum: number, period: number, percent: number) => {
  const rating = marks[percent];
  const percentValue = percentsByRating[rating];

  const p = parseFloat(`${percentValue / 12 / 100}`); // Percent per month
  const m = (sum * ((p * Math.pow(1 + p, period)) / (Math.pow(1 + p, period) - 1))).toFixed(2); // Payment per month
  const paymentPerMonth = parseFloat(m);

  fillPaymentSchedule(sum, period, percentValue, paymentPerMonth);
};

const fillPaymentSchedule = (sum: number, period: number, percent: number, paymentPerMonth: number) => {
  paymentData = [];
  let totalRepayment = 0;
  let totalPayment = 0;
  let summary = sum;

  for (let i = 1; i <= period; i++) {
    const interestPayment = summary * (percent / 12 / 100);
    const loanRepayment = paymentPerMonth - interestPayment;

    const paymentItem = {
      period: `${i}`,
      repayment: numeral(loanRepayment).format(intFormat),
      payment: numeral(interestPayment).format(intFormat),
      total: numeral(paymentPerMonth).format(intFormat),
      key: `${i}`
    };

    paymentData.push(paymentItem);

    totalRepayment += loanRepayment;
    totalPayment += interestPayment;
    summary -= loanRepayment;
  }

  const totalScheduleRow: IScheduleItem = {
    period: 'Total',
    repayment: numeral(totalRepayment).format(intFormat),
    payment: numeral(totalPayment).format(intFormat),
    total: numeral(totalRepayment + totalPayment).format(intFormat),
    key: `${period + 1}`
  };

  paymentData.push(totalScheduleRow);
};

export const LoanCalculator = Form.create({
  onFieldsChange(_props: AllProps, _changedFields: any, allFields: any) {
    const hasAllValues = !Object.values(allFields).find((item: any) => item.value === undefined || item.value === null);
    const hasErrors = !!Object.values(allFields).find((item: any) => item.errors);

    paymentData = [];

    if (allFields.rating.value === 100) {
      isRatingHighest = true;
      return;
    }

    isRatingHighest = false;

    if (hasAllValues && !hasErrors) {
      const sum = allFields.sum.value;
      const period = allFields.period.value;
      const rating = allFields.rating.value;

      calculate(sum, period, rating);
    }
  }
})(LoanCalculatorComponent);
