import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ERoutes } from 'common/const';
import { MainLayout } from 'common/components/MainLayout';
import { ScrollHandler } from 'common/components/ScrollHandler';
import { ComingSoonPage } from 'app/pages/ComingSoonPage';
import { Header } from 'entities/components/Header/Header';
import { LegalNotice } from 'entities/components/PrivacyPolicy/LegalNotice';
import { UserTerms } from 'entities/components/PrivacyPolicy/UserTerms';
import { DataPrivacy } from 'entities/components/PrivacyPolicy/DataPrivacy';
import { LoanCalculator } from 'entities/components/LoanCalculator/LoanCalculator';
import { FAQ } from 'entities/components/FAQ/FAQPage';
import { Academy } from 'entities/components/Academy/Academy';
import { AcademyTilePage } from 'entities/components/Academy/AcademyTilePage';
import { Footer } from 'entities/components/Footer/Footer';

class App extends React.Component<WithTranslation> {
  render() {
    const environment = process.env.REACT_APP_MDF_ENV || 'dev';

    return environment === 'production' ? (
      <Switch>
        <Route path="/" component={ComingSoonPage} />
      </Switch>
    ) : (
      <ScrollHandler>
        <Header />
        <Switch>
          <Route path={`/${ERoutes.LegalNotice}`} component={LegalNotice} exact />
          <Route path={`/${ERoutes.UserTerms}`} component={UserTerms} exact />
          <Route path={`/${ERoutes.DataPrivacy}`} component={DataPrivacy} exact />
          <Route path={`/${ERoutes.LoanCalculator}`} component={LoanCalculator} exact />
          <Route path={`/${ERoutes.FAQInvestor}`} component={FAQ} exact />
          <Route path={`/${ERoutes.FAQBorrower}`} component={FAQ} exact />
          <Route path={`/${ERoutes.Academy}`} component={Academy} exact />
          <Route path={`/${ERoutes.AcademyItem}`} component={AcademyTilePage} />
          <Route path={`/${ERoutes.Main}`} component={MainLayout} />
        </Switch>
        <Footer />
      </ScrollHandler>
    );
  }
}

export default withTranslation()(App);
