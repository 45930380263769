import React from 'react';
import { Trans } from 'react-i18next';
import { CTAInput } from 'common/components/CTAInput';
import { EUserRoles } from 'common/models/userRequestObjects';
import { ParalaxContainer } from 'common/components/ParalaxContainer';
import { CTAManBackgroundPlx, CTAManPlx } from 'common/PlxConfig';
import CTAMan from 'app/assets/images/CTAMan.svg';
import CTAManPlanets from 'app/assets/images/CTAManPlanets.svg';

export const CTASection = () => {
  return (
    <div className="CTA-section">
      <ParalaxContainer
        childrenParallaxData={[{ IParallaxData: CTAManBackgroundPlx }, { IParallaxData: CTAManPlx }]}
        className="CTAMan"
      >
        <img src={CTAManPlanets} className="CTA-section-image" alt="Man with lamp" width="100%" />
        <img src={CTAMan} className="CTA-section-image" alt="Man with lamp" width="100%" />
      </ParalaxContainer>
      <div className="CTA-section-block">
        <div className="CTA-section-block_title">
          <Trans i18nKey="CTA.title">
            <span className="ff-bold">Get your business loan</span>
            <br /> within a few minutes
          </Trans>
        </div>
        <CTAInput pathTo={`/sign-up?role=${EUserRoles.Borrower}`} />
      </div>
    </div>
  );
};
