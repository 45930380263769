import React from 'react';
import i18n from 'i18next';
import { Col, Row } from 'antd';
import { EAnchors } from 'common/const';
import { getAppDomainName, goTo } from 'common/helpers/domainName.helper';
import { EUserRoles } from 'common/models/userRequestObjects';
import { moneyTreePlx, moneyTreePlanets } from 'common/PlxConfig';
import { ParalaxContainer } from 'common/components/ParalaxContainer';
import MoneyTree from 'app/assets/images/MoneyTree.svg';
import MoneyTreeSun1 from 'app/assets/images/MoneyTreeSun1.svg';
import MoneyTreeSun2 from 'app/assets/images/MoneyTreeSun2.svg';
import MoneyTreePlanets from 'app/assets/images/MoneyTreePlanets.svg';

export const WhyMidfunder = () => {
  const goToSignUp = () => {
    const signUpUrl = getAppDomainName(`/sign-up?role=${EUserRoles.Investor}`);
    goTo(signUpUrl);
  };

  return (
    <div className="why-midfunder-section" id={EAnchors.WhyMDF}>
      <Row type="flex" gutter={32} align="middle">
        <Col xs={{ span: 24, order: 2 }} xl={{ span: 12, order: 1 }}>
          <div className="why-midfunder_title">{i18n.t<string>('whyMdf.title')}</div>
          <div className="why-midfunder_text">{i18n.t<string>('whyMdf.text')}</div>
          <div className="why-midfunder_comment">{i18n.t<string>('whyMdf.comment')}</div>
          <button className="why-midfunder_btn" onClick={goToSignUp}>
            {i18n.t<string>('whyMdf.button')}
          </button>
        </Col>
        <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }} className="text-center">
          <ParalaxContainer
            childrenParallaxData={[
              { IParallaxData: moneyTreePlanets },
              { IParallaxData: moneyTreePlx },
              { IParallaxData: moneyTreePlx },
              { IParallaxData: moneyTreePlx }
            ]}
            className="moneyTree"
          >
            <img src={MoneyTreePlanets} alt="Money Tree" width="100%" className="why-midfunder_image" />
            <img src={MoneyTreeSun1} alt="Money Tree" width="100%" className="why-midfunder_image" />
            <img src={MoneyTreeSun2} alt="Money Tree" width="100%" className="why-midfunder_image" />
            <img src={MoneyTree} alt="Money Tree" width="100%" className="why-midfunder_image" />
          </ParalaxContainer>
        </Col>
      </Row>
    </div>
  );
};
